import { BASE_API } from '@/api/v1'
import MaterialesRecepcion from '@/models/MaterialesRecepcion'
import BaseController from './BaseController'

class MaterialesRecepcionController extends BaseController {
  async create(object) {
    return super.create(`${BASE_API}/material-recepcion`, object)
  }

  async getAll(payload) {
    const res = await super.read(`${BASE_API}/materiales-recepcion?page=${payload.query.page}`, payload)
    const materiales = this.mapToArray(new Map(res.data.map(obj => [obj.id, new MaterialesRecepcion(obj)])))
    return {
      total: res.total,
      data: materiales,
    }
  }

  async listWithoutPagination(payload) {
    const res = await super.read(`${BASE_API}/materiales-recepcion`, payload)
    const materiales = this.mapToArray(new Map(res.map(obj => [obj.id, new MaterialesRecepcion(obj)])))
    return {
      total: materiales.length,
      data: materiales,
    }
  }

  async getById(id) {
    const res = await super.readOne(`${BASE_API}/material-recepcion/${id}`)
    return new MaterialesRecepcion(res[0])
  }

  async deleteById(id) {
    return super.delete(`${BASE_API}/materiales-recepcion/delete/${id}`)
  }

  /* eslint-disable-next-line class-methods-use-this */
  mapToArray(map) {
    return Array.from(map.values())
  }
}
export default MaterialesRecepcionController
