class MaterialesRecepcion {
  constructor({
    id, detalles, user_name, proveedor_name, fk_user, fk_proveedor, num_pedido_proveedor, fecha_recepcion,
  } = {}) {
    this.id = id
    this.fk_proveedor = fk_proveedor
    this.fk_user = fk_user
    this.num_pedido_proveedor = num_pedido_proveedor
    this.fecha_recepcion = fecha_recepcion
    this.detalles = detalles
    this.user_name = user_name
    this.proveedor_name = proveedor_name
  }
}

export default MaterialesRecepcion
