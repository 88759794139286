class Albaran {
  constructor({
    id, codigo, empleado_recoge_nombre, fk_pedido, pedido_codigo, fk_user_creado, fk_user_update, fk_empleado_recoge, fecha, estado, nota, detalles, fk_pedido_departamento, pedido_departamento_titulo,
  } = {}) {
    this.id = id
    this.fk_pedido = fk_pedido
    this.fk_user_creado = fk_user_creado
    this.fk_user_update = fk_user_update
    this.fk_empleado_recoge = fk_empleado_recoge
    this.fecha = fecha
    this.estado = estado
    this.nota = nota
    this.detalles = detalles
    this.fk_pedido_departamento = fk_pedido_departamento
    this.pedido_departamento_titulo = pedido_departamento_titulo
    this.pedido_codigo = pedido_codigo
    this.codigo = codigo
    this.empleado_recoge_nombre = empleado_recoge_nombre
  }
}

export default Albaran
