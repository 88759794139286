import DepartamentoController from '@/controllers/DepartamentoController'
import EmpleadoController from '@/controllers/EmpleadoController'
import UserController from '@/controllers/UserController'
import ProveedorController from '@/controllers/ProveedorController'
import DocController from '@/controllers/DocController'
import PlantillaController from '@/controllers/PlantillaController'
import MaterialController from '@/controllers/MaterialController'
import PedidoController from '@/controllers/PedidoController'
import PedidoProveedoresController from '@/controllers/PedidoProveedoresController'
import AlbaranController from '@/controllers/AlbaranController'
import MaterialesRecepcionController from '@/controllers/MaterialesRecepcionController'
import HistoricoMaterialesController from '@/controllers/HistoricoMateriales'
import TrabajoController from '@/controllers/TrabajoController'
import ZonaController from './ZonaController'
import MantenimientoController from './MantenimientoController'
import TareaController from './TareaController'

const depC = new DepartamentoController()
const empC = new EmpleadoController()
const usrC = new UserController()
const proC = new ProveedorController()
const docC = new DocController()
const plC = new PlantillaController()
const matC = new MaterialController()
const pedC = new PedidoController()
const albC = new AlbaranController()
const pepC = new PedidoProveedoresController()
const marC = new MaterialesRecepcionController()
const htmC = new HistoricoMaterialesController()
const zonaC = new ZonaController()
const mantC = new MantenimientoController()
const trabC = new TrabajoController()
const tareaC = new TareaController()

export function useModule(module) {
  return module === 'departamento'
    ? depC
    : module === 'empleado'
      ? empC
      : module === 'user'
        ? usrC
        : module === 'docs'
          ? docC
          : module === 'plantilla'
            ? plC
            : module === 'materiales'
              ? matC
              : module === 'pedidos'
                ? pedC
                : module === 'albaranes'
                  ? albC
                  : module === 'pedidos-proveedores'
                    ? pepC
                    : module === 'materiales-recepcion'
                      ? marC
                      : module === 'historico-materiales'
                        ? htmC
                        : module === 'zona'
                          ? zonaC
                          : module === 'mantenimiento'
                            ? mantC
                            : module === 'trabajo'
                              ? trabC
                              : module === 'tarea'
                                ? tareaC
                                : proC
}
