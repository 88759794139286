class HistoricoMaterial {
  constructor({
    product_ref, product_description, delivery_date, pedido_num, fk_albaran,
  } = {}) {
    this.fk_albaran = fk_albaran
    this.pedido_num = pedido_num
    this.product_ref = product_ref
    this.product_description = product_description
    this.delivery_date = delivery_date
  }
}

export default HistoricoMaterial
