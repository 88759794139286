import { BASE_API } from '@/api/v1'
import PedidoProveedor from '@/models/PedidoProveedor'
import BaseController from './BaseController'

class PedidoProveedoresController extends BaseController {
  async create(object) {
    return super.create(`${BASE_API}/pedido-proveedor`, object)
  }

  async getAll(payload) {
    const res = await super.read(`${BASE_API}/pedidos-proveedores?page=${payload.query.page}`, payload)
    const pedidos = this.mapToArray(new Map(res.data.map(obj => [obj.id, new PedidoProveedor(obj)])))
    return {
      total: res.total,
      data: pedidos,
    }
  }

  async getById(id) {
    const res = await super.readOne(`${BASE_API}/pedido-proveedor/${id}`)
    return new PedidoProveedor(res)
  }

  async deleteById(id) {
    return super.delete(`${BASE_API}/pedidos-proveedores/delete/${id}`)
  }

  async checkIfPedidoExistsForProveedor(proveedorId) {
    return super.readOne(`${BASE_API}/pedido-proveedor-borrador/${proveedorId}`)
  }

  async sendPedidoProveedor(proveedorId) {
    return super.create(`${BASE_API}/pedido-proveedor-send/${proveedorId}`)
  }

  async printPedidoProveedor(proveedorId) {
    return super.readOne(`${BASE_API}/pedido-proveedor-print/${proveedorId}`)
  }

  /* eslint-disable-next-line class-methods-use-this */
  mapToArray(map) {
    return Array.from(map.values())
  }
}
export default PedidoProveedoresController
