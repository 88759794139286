import { BASE_API } from '@/api/v1'
import Albaran from '@/models/Albaran'
import BaseController from './BaseController'

class AlbaranController extends BaseController {
  async create(object) {
    return super.create(`${BASE_API}/albaran`, object)
  }

  async getAll(payload) {
    const res = await super.read(`${BASE_API}/albaranes?page=${payload.query.page}`, payload)
    const albaranes = this.mapToArray(new Map(res.data.map(obj => [obj.id, new Albaran(obj)])))
    return {
      total: res.total,
      data: albaranes,
    }
  }

  async getById(id) {
    const res = await super.readOne(`${BASE_API}/albaran/${id}`)
    return new Albaran(res[0])
  }

  async printAlbaran(id) {
    return super.readOne(`${BASE_API}/albaran-print/${id}`)
  }

  async deleteById(id) {
    return super.delete(`${BASE_API}/albaranes/delete/${id}`)
  }

  /* eslint-disable-next-line class-methods-use-this */
  mapToArray(map) {
    return Array.from(map.values())
  }
}
export default AlbaranController
