import { BASE_API } from '@/api/v1'
import ZonaModel from '@/models/Zona'
import BaseController from './BaseController'

class ZonaController extends BaseController {
  async createZone(object) {
    return super.create(`${BASE_API}/zona`, object)
  }

  async updateZona(object) {
    return super.create(`${BASE_API}/zona`, object)
  }

  async getAllZonas(payload) {
    const res = await super.read(`${BASE_API}/zonas?page=${payload.query.page}`, payload)
    const zonas = this.mapToArray(new Map(res.data.map(obj => [obj.id, new ZonaModel(obj)])))
    return {
      total: res.total,
      data: zonas,
    }
  }

  async delete(id) {
    return super.delete(`${BASE_API}/zona/delete/${id}`)
  }

  async listWithoutPagination(payload) {
    const res = await super.read(`${BASE_API}/zonas`, payload)
    const zonas = this.mapToArray(new Map(res.map(obj => [obj.id, new ZonaModel(obj)])))
    return {
      total: zonas.length,
      data: zonas,
    }
  }

  mapToArray(map) {
    return Array.from(map.values())
  }
}
export default ZonaController
