import { BASE_API } from '@/api/v1'
import BaseController from './BaseController'
import MantenimientoModel from '../models/Mantenimiento'

class MantenimientoController extends BaseController {
  async createMantenimiento(object) {
    return super.create(`${BASE_API}/mantenimiento`, object)
  }

  async getAllMantenimientos(payload) {
    const res = await super.read(`${BASE_API}/mantenimientos?page=${payload.query.page}`, payload)

    const manteniminetos = this.mapToArray(new Map(res.data.map(obj => [obj.id, new MantenimientoModel(obj)])))
    return {
      total: res.total,
      data: manteniminetos,
    }
  }

  async deleteMantenimientosById(id) {
    return super.delete(`${BASE_API}/mantenimiento/delete/${id}`)
  }

  async listZoneParentMantenimientos(payload) {
    const res = await super.read(`${BASE_API}/zona-parent-mantenimientos?page=${payload.query.page}`, payload)
    const mantenimientos = this.mapToArray(new Map(res.data.map(obj => [obj.id, new MantenimientoModel(obj)])))
    return {
      total: res.total,
      data: mantenimientos,
    }
  }

  async fetchMantenimientosByLocation(payload) {
    const res = await super.read(`${BASE_API}/zona-mantenimientos?page=${payload.query.page}`, payload)
    const mantenimientos = this.mapToArray(new Map(res.data.map(obj => [obj.id, new MantenimientoModel(obj)])))
    return {
      total: res.total,
      data: mantenimientos,
    }
  }

  async listWithoutPagination(payload) {
    const res = await super.read(`${BASE_API}/mantenimientos`, payload)
    const mantenimientos = this.mapToArray(new Map(res.map(obj => [obj.id, new MantenimientoModel(obj)])))
    return {
      total: mantenimientos.length,
      data: mantenimientos,
    }
  }

  mapToArray(map) {
    return Array.from(map.values())
  }
}

export default MantenimientoController
