class PedidoProveedor {
  constructor({
    id, fk_user_creado, detalles, proveedor_name, fk_proveedor, total_coste, is_urgent, fecha_enviado, pedido_from_codigo, user_creado_name, fk_material, fk_pedido_from, unidades, codigo, fecha, estado, nota,
  } = {}) {
    this.id = id
    this.fk_user_creado = fk_user_creado
    this.fk_material = fk_material
    this.fk_pedido_from = fk_pedido_from
    this.fk_proveedor = fk_proveedor
    this.unidades = unidades
    this.codigo = codigo
    this.fecha = fecha
    this.estado = estado
    this.user_creado_name = user_creado_name
    this.pedido_from_codigo = pedido_from_codigo
    this.nota = nota
    this.total_coste = total_coste
    this.is_urgent = is_urgent
    this.fecha_enviado = fecha_enviado
    this.proveedor_name = proveedor_name
    this.detalles = detalles
  }
}

export default PedidoProveedor
