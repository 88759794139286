class Material {
  constructor({
    id, created_at, coste_diario, barcode, updated_at, fk_proveedor, codigo, descripcion, descripcion_custom, coste, tipo, medida, desechable, tallaCamiseta, tallaPantalon, color, estado, stock, stock_minimo, nota, albaranes_detalles_count, materiales_recepcions_count, partes_de_trabajo_materiales_count, pedido_detalles_count, pedidos_proveedores_count,
  } = {}) {
    this.id = id
    this.created_at = created_at
    this.updated_at = updated_at
    this.fk_proveedor = fk_proveedor
    this.codigo = codigo
    this.descripcion = descripcion
    this.descripcion_custom = descripcion_custom
    this.coste = coste
    this.tipo = tipo
    this.medida = medida
    this.desechable = desechable
    this.tallaCamiseta = tallaCamiseta
    this.tallaPantalon = tallaPantalon
    this.color = color
    this.estado = estado
    this.stock = stock
    this.stock_minimo = stock_minimo
    this.nota = nota
    this.barcode = barcode
    this.coste_diario = coste_diario
    this.albaranes_detalles_count = albaranes_detalles_count
    this.materiales_recepcions_count = materiales_recepcions_count
    this.partes_de_trabajo_materiales_count = partes_de_trabajo_materiales_count
    this.pedido_detalles_count = pedido_detalles_count
    this.pedidos_proveedores_count = pedidos_proveedores_count
  }
}

export default Material
