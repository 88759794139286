import { BASE_API } from '@/api/v1'
import Material from '@/models/Material'
import BaseController from './BaseController'

class MaterialController extends BaseController {
  async create(object) {
    return super.create(`${BASE_API}/material`, object)
  }

  async getAll(payload) {
    const res = await super.read(`${BASE_API}/materiales?page=${payload.query.page}`, payload)
    const materiales = this.mapToArray(new Map(res.data.map(obj => [obj.id, new Material(obj)])))
    return {
      total: res.total,
      data: materiales,
    }
  }

  async listWithoutPagination(payload) {
    const res = await super.read(`${BASE_API}/materiales`, payload)
    const materiales = this.mapToArray(new Map(res.map(obj => [obj.id, new Material(obj)])))
    return {
      total: materiales.length,
      data: materiales,
    }
  }

  async getById(id) {
    const res = await super.readOne(`${BASE_API}/material/${id}`)
    return new Material(res[0])
  }

  async changeStatus(data) {
    const res = await super.create(`${BASE_API}/material-status/${data.id}`, { estado: data.status })
    return new Material(res[0])
  }

  async deleteById(id) {
    return super.delete(`${BASE_API}/materiales/delete/${id}`)
  }

  /* eslint-disable-next-line class-methods-use-this */
  mapToArray(map) {
    return Array.from(map.values())
  }
}
export default MaterialController
