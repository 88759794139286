class Pedido {
  constructor({
    id, is_urgent, fk_user_creado, user_creado_name, user_aprobado_name, fk_user_aprobado, fk_departamento, fk_albaran, codigo, fecha, total_coste, estado, descripcion, descripcion_custom, nota, detalles,
  } = {}) {
    this.id = id
    this.fk_user_creado = fk_user_creado
    this.fk_user_aprobado = fk_user_aprobado
    this.fk_departamento = fk_departamento
    this.fk_albaran = fk_albaran
    this.codigo = codigo
    this.fecha = fecha
    this.total_coste = total_coste
    this.estado = estado
    this.descripcion = descripcion
    this.descripcion_custom = descripcion_custom
    this.nota = nota
    this.detalles = detalles
    this.user_creado_name = user_creado_name
    this.user_aprobado_name = user_aprobado_name
    this.is_urgent = is_urgent
  }
}

export default Pedido
